.c-sidebar {
  width: 70%;
  z-index: 3;
  overflow-y: scroll;
  position: absolute;
  padding-bottom: 111px;
  flex-direction: column;
  background-color: $grey-600;
  height: calc(100vh - 111px);

  @include responsive (md) {
    z-index: 0;
    min-width: 27%;
    max-width: 27%;
    padding-bottom: 0;
    position: relative;
    height: calc(100vh - 90px);
  }

  &__closed {
    width: 80px;
    min-height: calc(100vh - 105px);
    border-left: 5px solid $brand-color-primary;
    background-color: $grey-300;
    display: none;

    @include responsive (md) {
      display: block;
      min-width: 80px;
    }
  }

  &__avatar {
    border-top: 1px solid $grey-200;
  }

  &__user-section {
    border-top: 1px solid $grey-200;
  }

  &__icon-container {
    display: none;

    @include responsive (md) {
      display: block;
      position: absolute;
      top: 35px;
      right: 14px;
      transform: translateY(-50%);
      padding: 5px;
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
  }

  &__icon {
    &--rotate {
      transform: rotate(180deg);
    }
  }
}