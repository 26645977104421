.c-tag-results {
  top: 100%;
  left: 0;
  max-height: 194px;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  z-index: 3;

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    list-style-type: none;
    padding: 18px 24px;
    border-bottom: .5px solid $grey-200;
    cursor: pointer;
  }
}
