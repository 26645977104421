.c-header-module {
  border-bottom: 1px solid $grey-200;
  margin-bottom: 16px;
  padding-bottom: 2rem;

  @include responsive(md) {
    margin-bottom: 16px;
    padding-bottom: 24px;
  }

  &__left-container {
    min-width: 0;
  }

  &__title-container {
    min-width: 0%;
    padding-right: 10px;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
  }
}