.c-files-modal {
  max-height: min(640px, calc(100vh - 3.5rem));
  &__dialog {
    max-width: calc(100vw - 1rem);
    max-width: min(936px, calc(100vw - 1rem));
  }
  &__body {
    border-bottom: 0.5px solid $grey-200;
    border-top: 0.5px solid $grey-200;
  }
}

.c-documents-modal {
  height: min(720px, calc(100vh - 3.5rem));
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  &__dialog {
    max-width: 80%;
  }
  &__body {
    min-height: 250px;
    border-bottom: 0.5px solid $grey-200;
    border-top: 0.5px solid $grey-200;
  }
}


.modal:nth-of-type(even) {
  z-index: 1056 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1055 !important;
}