.c-sso-footer {
  &__link,
  &__link:hover,
  &__separator {
    color: $white;

    @include responsive(md) {
      color: $grey-100;
    }
  }

  &__link {
    border-bottom: 1px solid $grey-100;
    text-decoration: none;
  }

  &__bottom-label {
    color: $white;
    // padding-right: 10px;
    // padding-bottom: 7px;
    display: block;
    // cursor: pointer;

    @include responsive(md) {
      color: $grey-100;
    }
  }
}