.c-action-popup {
  max-width: 460px;
  max-height: 250px;
  padding: 30px;

  &__title {
    color: $grey-100;
    margin-bottom: 15px;
  }

  &__subtitle {
    color: $grey-100;
    margin-bottom: 30px;
  }
}
