.c-tag-input {
  margin: 12px 0;

  @include responsive(md) {
    margin: 14px 0;
  }

  &__label {
    color: $grey-200;
    padding-left: 8px;

    &--active {
      color: $brand-color-primary;
    }

    &--error {
      color: $red;
    }
  }

  &__input {
    color: $dark-gray;
    display: block;
    width: 100%;
    border: none;
    height: 100%;
    padding: 0.375rem 0.64rem;
    padding-left: calc(8px + .64rem);

    background: {
      clip: padding-box;
      color: $transparent;
    }

    &:focus-visible {
      outline: none;
    }

    &-container {
      // padding: 0.375rem 0.64rem;
      line-height: 1.5;
      border: 1px solid $gray-400;
      appearance: none;
      border-radius: 0.25rem;
      height: 44px;

      &--active {
        border: 1px solid $brand-color-primary;
      }

      &--error {
        border: 1px solid $red;
      }
    }
  }

  &__error-message {
    font-size: .75rem;
    color: $red;
  }

  &__icon-container {
    cursor: pointer;
  }
}
