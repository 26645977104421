.v-my-stores {
  gap: 8px;
  justify-content: space-between;

  @include responsive(xs) {
    gap: 20px;
    justify-content: unset;
  }

  @include responsive(md) {
    gap: 20px;
  }

  &__empty-state-description {
    width: 80%;

    @include responsive(md) {
      width: 50%;
    }
  }
}

.c-store-owner {
  max-width: 200px;

  &__picture {
    width: 30px;
    height: 30px;
  }

  &__info {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}