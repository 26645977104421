.c-search-popup {
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  background-color: white;
  width: 96%;

  @include responsive (md) {
    width: 93%;
  }

  &__title-section {
    padding: 15px 0 15px 25px;
  }

}
