.c-dots-submenu {
  border-radius: 10px;
  box-shadow: 0 0 15px rgba($black, 0.1);
  list-style: none;
  top: 100%;
  min-width: 165px;
  max-width: 340px;
  width: max-content;
  z-index: 5;

  &__overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgba(#000000, .3);
  }

  &__list {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    list-style: none;
    width: max-content;
    z-index: 5;
    list-style: none;
    padding: 10px;

    &-item {
      display: flex;
      align-items: center;
      border-left: 4px solid transparent;
      transition: border 0.3s ease;
      padding: 0 10px;
      margin-bottom: 12px;

      &-checkbox {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
      }
    }
  }

  &__item {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 92px;
  }

  &__dropdown {
    position: fixed;
    height: max-content;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 15px rgba($black, 0.1);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 10px 0;
    z-index: 2;
    overflow: hidden;
    transition: all .5s ease;
    cursor: pointer;

    &-left{
      @include responsive(sm) {
      position: absolute;
      top: 31px;
      right: 9px;
      min-width: 190px;
      border-radius: 10px;
    }
    }
    
    &-right{
      @include responsive(sm) {
        position: absolute;
        top: 42px;
        right: 0;
        left: 16px;
        width: 190px;
        border-radius: 10px;
      }
    }
    &-bottom-slide {
      bottom: -200px;
      animation: slideIn 1s ease forwards;
    }
  }

  &__menu {
    width: 100%;
    overflow-y: auto;
    height: 50vh;
    padding-bottom: 20px;

    @include responsive(md) {
      height: auto;
      padding-bottom: 0;
    }

    &-item {
      height: 35px;
      display: flex;
      align-items: center;
      transition: border .3s ease;
      color: $grey-100;
      text-decoration: none;
      padding: 0 20px;
      font-size: 14px;
      cursor: default;
      @include responsive(md) {
        height: 32px;
        padding: 0 15px;
      }

      &:hover {
        border-left: 4px solid $brand_color-secondary;
        font-weight: 700;
        transition: border .3s ease;
        color: $grey-100;
      }
    }
  }
}


:root {
  --speed: 500ms;
}


/* Icon Button */

// .icon-button:hover {
//   filter: brightness(1.2);
// }

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}


.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.c-dots-header {
  padding: 20px;
  border-bottom: 1px solid $grey-200;
  margin-bottom: 12px;
  padding-left: 20px;
  display: flex;
  align-items: center;

  @include responsive(md) {
    border-bottom: unset;
    padding: unset;
  }

  &__title {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }
}


@keyframes slideIn {
  0% {
    bottom: -200px;
  }

  100% {
    bottom: 0;
  }
}