.c-action-sheet {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";

  &__actions-container {
    border-top: 1;
    border-bottom: 1;
    border-color: grey;
  }

  &__action:active {
    font-weight: 700;
  }
}
