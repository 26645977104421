.c-text-click {
  &__link {
    color: $brand-color-secondary;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 25px;
    display: block;
  }

  &__separator {
    color: $brand-color-secondary;
    opacity: 1;
    height: 1px;
    width: 70px;
    margin: 8px auto;
  }
}
