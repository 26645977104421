.c-step-status {
  width: 150px;
  border-radius: 80px;
  background-color: $grey-300;
  padding: 6px;

  &--disabled {
    color: #ccc;
  }

  &__icon-container {
    background-color: $grey-200;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &__icon-container-orange {
    background-color: $yellow;
  }

  &__icon-container-green {
    background-color: $green;
  }

  &__icon-container-grey {
    background-color: $grey-200;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
  }

  &__option-list {
    background-color: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 185px;
    top: 38px;
    right: 16px;
    z-index: 1;

    @include responsive (md) {
      width: 240px;
      right: 39px;
    }
  }

  &__option {
    list-style: none;
    margin: 12px 20px;
  }

  &__title {
    &--active {
      font-weight: 700;
    }

  }
}
