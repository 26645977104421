.c-table {
  $error-border: 1px solid $red;
  overflow-wrap: anywhere;
  table-layout: fixed;
  width: 100%;

  &__thead-row {
    border-bottom: 0.5px solid $grey-200;
    padding: 1rem 0;
    white-space: normal;
    line-height: 0.75rem;

    @include responsive(lg) {
      line-height: var(--bs-body-line-height);
    }
  }

  &__title {

    &--collapsable {
      display: none;

      @include responsive(lg) {
        display: table-cell;
      }
    }

    &--collapsable-desktop {
      display: table-cell;

      @include responsive(lg) {
        display: none;
      }
    }

    &--hidden {
      display: none;
    }

    &--hidden-mobile {
      visibility: hidden;

      @include responsive(lg) {
        visibility: visible;
      }
    }
  }

  &__row {

    &--border {
      border: 2px dashed $brand-color-primary;
    }

    border-top: 0.5px solid $grey-200;

    &--collapsable {
      display: none;

      @include responsive(lg) {
        display: table-cell;
      }
    }

    &--collapsable-desktop {
      display: table-cell;

      @include responsive(lg) {
        display: none;
      }
    }
  }

  &__row:not(&__row--error):last-of-type & {
    &__row-data {
      border-bottom: 0.5px solid $grey-200;
    }
  }

  &__row--error & {
    &__row-data {
      border-bottom: $error-border;
      border-top: $error-border;

      &:first-of-type {
        border-left: $error-border;
      }

      &:last-of-type {
        border-right: $error-border;
      }
    }
  }

  &--no-borders &__row:first-of-type:not(&__row--error) &__row-data {
    border-top: 0.5px solid transparent;
  }

  &--no-borders &__row:last-of-type:not(&__row--error) &__row-data {
    border-bottom: 0.5px solid transparent;
  }

  &--selectable &__row &__row-data:first-of-type {
    // border-bottom: unset;
    // border-top: unset;
  }
}
