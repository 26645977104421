.backdrop {
  pointer-events: auto;
  inset: 0px;
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  mix-blend-mode: multiply;
}

.spotlight {
  border-radius: 10px;
  position: absolute;
  background-color: #fff;
  pointer-events: auto;
  transition: opacity 0.2s ease 0s;

  &--favorites {
    height: 52px;
    width: 52px;
    top: 35px;
    right: 13%;
    border-radius: 50%;
  }

  &--search {
    height: 52px;
    width: 52px;
    top: 35px;
    right: 3%;
    border-radius: 50%;
  }

  &--menu {
    height: 52px;
    width: 52px;
    top: 35px;
    left: 8%;
    border-radius: 50%;

    @include responsive (md) {
      display: none;
    }
  }

  &--dashboard {
    height: 56px;
    width: 56px;
    top: 88vh;
    right: 45%;
    border-radius: 50%;
  }

  &--latest-files {
    height: 62px;
    width: 190px;
    top: 54vh;
    left: 3%;
    border-radius: 12px;
  }

  @include responsive (sm) {

    &--search {
      height: 52px;
      width: 52px;
      top: 35px;
      right: 4%;
      border-radius: 50%;
    }
  }

  @include responsive (md) {
    &--search {
      height: 62px;
      width: 33%;
      top: 20px;
      right: 40%;
      border-radius: 12px;
    }

    &--favorites {
      height: 60px;
      width: 12%;
      top: 22px;
      right: 29%;
      border-radius: 20px;
    }

    &--sidebar {
      height: 500px;
      width: 25%;
      top: 100px;
      border-radius: 20px;
    }

    &--dashboard {
      height: 50px;
      width: 194px;
      top: 126px;
      left: 25%;
      border-radius: 12px;
    }

    &--latest-files {
      height: 62px;
      width: 214px;
      top: 59vh;
      left: 26%;
      border-radius: 12px;
    }
  }

  @include responsive(lg) {
    &--dashboard {
      height: 58px;
      width: 190px;
      top: 128px;
      left: 26%;
      border-radius: 12px;
    }
  }

  @include responsive (xl) {
    &--search {
      height: 62px;
      width: 34%;
      top: 20px;
      right: 40%;
    }

    &--favorites {
      height: 62px;
      width: 12%;
      top: 20px;
      right: 30%;
    }

    &--sidebar {
      height: 500px;
      width: 25%;
      top: 100px;
    }

    &--latest-files {
      height: 62px;
      width: 214px;
      top: 70vh;
      left: 26%;
      border-radius: 12px;
    }
  }

  @include responsive (xxl) {
    &--search {
      height: 62px;
      width: 34%;
      top: 20px;
      right: 40%;
    }

    &--favorites {
      height: 62px;
      width: 12%;
      top: 20px;
      right: 29%;
    }

  }

  @include responsive (xxxl) {
    &--search {
      height: 62px;
      width: 34%;
      top: 20px;
      right: 40%;
    }

    &--favorites {
      height: 62px;
      width: 9%;
      top: 20px;
      right: 31%;
    }
  }

}

.c-onboarding-tooltip-float {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  display: none;
  // left: 10px;
  // top: 120px;
  z-index: 5;

}


.c-onboarding-tooltip {
  position: relative;

  &__close-button {
    position: absolute;
    top: 11px;
    right: 11px;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    outline: 0;
    padding: 20px;
    border-radius: 20px;
  }

  &-header {
    flex-shrink: 0;
    align-items: center;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    border-top: transparent !important;
    border-bottom: transparent !important;
  }

  &-body {
    position: relative;
    flex: 1 1 auto;
  }

  &-footer {
    flex-wrap: wrap;
    flex-shrink: 0;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  &__clippath-section {
    clip-path: polygon(0 0, 100% 0%, 100% 37%, 0 38%);
    padding: 20px;
  }

  // tooltip arrows position
  &__arrow {
    content: '';
    content: "\a";
    border-style: solid;
    border-width: 18px 20px 16px 0;
    border-color: transparent white transparent transparent;
    position: absolute;
  }

  &__arrow-top-left {
    left: 30px;
    top: -25px;
    transform: rotate(91deg);

  }

  &__arrow-top-right {
    right: 20px;
    top: -25px;
    transform: rotate(91deg);
  }

  &__arrow-left {
    left: -19px;
    top: 31px;
    transform: rotate(0deg);
  }

  &__arrow-right {
    right: -20px;
    top: 31px;
    transform: rotate(180deg);
  }

  &__arrow-bottom {
    right: 50%;
    bottom: -25px;
    transform: rotate(270deg);
  }

  //arrows end


  &__title {
    color: $grey-100;
  }

  &__description {
    max-width: 250px;
    margin-bottom: 5px;
  }

  &__dot {
    background-color: $grey-200;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: 0 2px;
    display: inline-block;

    &--active {
      background-color: $grey-100;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin: 0 2px;
      display: inline-block;
    }
  }

  &__next-button {
    color: $brand-color-primary;
    margin: 0 8px;
  }

  &__back-button {
    color: $grey-100;
    margin: 0 8px;
  }

  &__clippath-section {
    background-color: white;
    clip-path: circle(50%);
    position: absolute;
    top: -70%;
  }


  // tooltips positions///
  &--search-position {
    top: 110px;
    right: 2%;
  }

  &--favorites-position {
    top: 110px;
    right: 6%;
  }

  &--dashboard-position {
    bottom: 122px;
    left: 25%;
  }

  &--sidebar-position {
    display: none;
  }

  &--menu-position {
    top: 120px;
    left: 2%;
  }

  &--latest-files-position {
    top: 38vh;
    left: 4%;
    max-height: 179px;
  }

  @include responsive(md) {
    &--search-position {
      top: 115px;
      right: 47%;
    }

    &--favorites-position {
      top: 115px;
      right: 17%;
    }

    &--dashboard-position {
      top: 215px;
      left: 27%;
      max-height: 179px;
    }

    &--latest-files-position {
      top: 67vh;
      left: 27%;
      max-height: 179px;
    }

    &--sidebar-position {
      display: block;
      top: 125px;
      left: 27%;
    }

    &--menu-position {
      display: none;
    }
  }

  @include responsive(xl) {
    &--latest-files-position {
      top: 82vh;
      left: 27%;
      max-height: 179px;
    }
  }

}

// .c-onboarding-tooltip--search-position {
//   top: 110px;
//   right: 2%;
// }

// .c-onboarding-tooltip--favorites-position {
//   top: 110px;
//   right: 6%;
// }

// .c-onboarding-tooltip--dashboard-position {
//   bottom: 105px;
//   left: 25%;
// }

// .c-onboarding-tooltip--sidebar-position {
//   display: none;
// }

// .c-onboarding-tooltip--menu-position {
//   top: 120px;
//   left: 2%;
// }

// .c-onboarding-tooltip--latest-files-position {
//   bottom: 38%;
//   left: 4%;
//   max-height: 179px;
// }

// @include responsive(md) {
//   .c-onboarding-tooltip--search-position {
//     top: 115px;
//     right: 47%;
//   }

//   .c-onboarding-tooltip--favorites-position {
//     top: 115px;
//     right: 17%;
//   }

//   .c-onboarding-tooltip--dashboard-position {
//     top: 215px;
//     left: 27%;
//     max-height: 179px;
//   }

//   .c-onboarding-tooltip--latest-files-position {
//     bottom: 4%;
//     left: 27%;
//     max-height: 179px;
//   }

//   .c-onboarding-tooltip--sidebar-position {
//     display: block;
//     top: 125px;
//     left: 27%;
//   }

//   .c-onboarding-tooltip--menu-position {
//     display: none;
//   }
// }
