.c-avatar {
  justify-content: flex-end;

  &--loading {
    display: grid;
    grid-template-columns: 0.5fr 2fr;

    @include responsive(md) {
      grid-template-columns: 0.5fr;
    }

    @include responsive(lg) {
      grid-template-columns: 0.5fr 1fr;
    }

    @include responsive(xl) {
      grid-template-columns: 0.5fr 2fr;
    }
  }

  &__favorites-section {
    padding: 0 10px;
    text-align: right;
    white-space: nowrap;
  }

  &__user-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8px;
    text-align: left;
    white-space: nowrap;

    @include responsive(md) {
      display: none;
    }

    @include responsive(lg) {
      display: block;
      border-top: unset
    }

  }

  &__name {
    background: $transparent;
    border: none;
    color: $grey-200;
  }

  &__email {
    color: $grey-200;
  }

  &__email,
  &__name {
    display: block;
    text-align: left;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include responsive(sm) {
      width: 200px;
    }

    @include responsive(md) {
      width: 0;
    }

    @include responsive(lg) {
      width: 77px;
    }

    @include responsive(xl) {
      width: 100px;
    }
  }

  &__user-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;

    @include responsive(md) {
      width: 53px;
      height: 53px;
      padding: 8px;
      margin:unset;
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__mobile-search {
    margin-left: 5px;
  }
}
