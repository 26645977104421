.front-app {
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif !important;
}

body .sidebar-pills {
  font-family: 'Source Sans Pro', sans-serif !important;
  background-color: #f1f4f8 !important;
}

body.sidebar-show {
  overflow: hidden !important;
}

.app-header.navbar {
  padding-top: 0;
  background-color: #f1f4f8 !important;
}

.navbar-toggler-icon {
  background-size: contain;
}

#pdf-download {
  display: none;
}

#no-renderer-download {
  display: none;
}



@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
// @import '../brand/sass/brand-settings',
@import '../brand/sass/brand-settings',
'settings',
'tools/index',
'objects/index',
'components/index',
'views/index',
'utilities',
'elements'
;

/*
  Bad
  ------
  padding/margin: 0;
  !important
  specificity -> .tag > span.button a.link
  #ids
  HTML tags
  style inline

  div class=.compo
    a class=.compo__link

  .compo {
    &__link {

    }
  }
*/

.slick-dots {
  bottom: -15px !important;
}

.slick-dots li.slick-active button:before {
  color: $brand-color-primary !important;
}

.slick-dots li button:before {
  color: $brand-color-primary !important;
  font-size: 10px !important;
}