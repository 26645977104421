.request-text {
	margin-left: 8px;
	font-size: 12px;
}

.email-button {
	display: flex;
	align-items: center;

	&:hover {
		color: $brand-color-secondary;
	}

	@include responsive(sm) {
		justify-content: end;
	}
}