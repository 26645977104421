.c-menu-subsection-item {
  color: $grey-200;
  text-decoration: none;
  list-style: none;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $grey-100;
  }

  &--active {
    color: $grey-100;
  }

  &--child {
    padding: 10px 8px 0 60px;
  }

  &--grandchild {
    padding: 2px 8px 2px 25px;
  }

  &__second {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: $grey-100;
    }

    &--active {
      color: $grey-100;
    }
  }
}
