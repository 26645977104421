.c-favorite-link {

  &__desktop-section {
    min-width: 124px;
  }

  &__link-section {
    padding-left: 8px;
  }

  &__link {
    background: $transparent;
    border: none;
    color: $grey-200;
    padding: 0;

    &:focus {
      box-shadow: none;
    }

    &--active {
      color: $brand-color-primary;
    }
  }
}
