.c-steps-group-input {
  display: flex;
  justify-content: space-between;
  min-height: 160px;
  flex-direction: column;
}



@media (min-width: 1648px) {
  .c-steps-input-second-big {
    margin-top: 20px;
  }
  .c-steps-input-second {
    margin-top: 20px;
  }
}

@media (min-width: 592px) and (max-width: 704px) {
  .c-steps-input-second-big {
    margin-top: 22px;
  }
  .c-steps-input-second {
    margin-top: 22px;
  }
}

@media (min-width: 900px) and (max-width: 1068px)  {
  .c-steps-input-second-big {
    margin-top: 22px;
  }
  .c-steps-input-second {
    margin-top: 22px;
  }
}



.c-steps-textarea-container{
  color: var(--Grey-2, #9FA0A0);
  text-align: right;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  bottom: 0px;
  right: 20px;
  position: absolute;
}

.c-steps-textarea-limit{
  color: #E24325 !important

}

