.c-menu-item {
  position: relative;
  list-style: none;

  &__skeleton {
    display: inline-block;
    color: $grey-100;
    text-decoration: none;
    padding: 22px 66px 0 30px;
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__link {
    display: inline-block;
    color: $grey-100;
    text-decoration: none;
    padding: 22px 55px 22px 30px;
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--active,
    &:hover {
      background-color: $grey-300;
    }

    &:hover {
      color: $grey-100;
    }

    &--active {
      border-left: 4px solid $brand-color-primary;
    }
  }
}