@mixin responsive ($breakpoint, $orientation: '') {
  @if(map-has-key($breakpoints, $breakpoint)) {
    @if($orientation ==$portrait or $orientation ==$landscape) {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) and (orientation: $orientation) {
        @content;
      }
    }

    @else {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  }

  @else if(type-of($breakpoint)==number and unit($breakpoint)=='px') {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  }
}


//   @media only screen 
// and (min-device-width : 375px) 
// and (max-device-width : 667px) { /* STYLES GO HERE */}

@mixin device ($device) {
  @if($device =='iphone7') {
    @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
      @content
    }
  }
}