.c-search {
  $parent: &;
  width: 100%;
  border-radius: 4px;
  padding: 0 0 0 15px;
  height: 50px;

  &__container {
    height: 50px;
    @include responsive (md) {
      width: 300px;
    }

    @include responsive (lg) {
      width: 360px;
    }

    @include responsive (xl) {
      width: 450px;
    }
  }
  &-module {
    padding: 0 22px 0 15px;

    #{$parent}__input {
      padding-left: 6px;
    }
  }

  &__border {
    border: 1px solid $grey-200;
  }

  &__input {
    border: none;
    // padding-left: 12px;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  &__close-icon {
    cursor: pointer;
  }

  &__search-icon-container {
    margin-right: 12px;
  }

  &--colored-active {
    border: 1px solid $brand-color-secondary;
  }

  &--colored-error {
    border: 1px solid $red;
  }

  &__desactive-icon {
    color: #9FA0A0;
  }

  &__colored-icon {
    color: $brand-color-secondary;
  }

  &__clean-icon {
    display: none;
    right: 15px;
  }

  &__file-result-name {
    padding-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      overflow: auto;
      text-overflow: unset;
      white-space: normal;
    }
  }
}