.c-contact-table {
  width: 100%;

  &__thead-row {
    border-bottom: .5px solid $grey-200;
    padding: 1rem 0;
  }

  &__title {
    color: $grey-100;
    padding: 0 .5rem .7rem .5rem;
  }

  &--collapsable {
    display: none;

    @include responsive(md) {
      display: table-cell;
    }
  }
}
