.c-action {
  background-color: $grey-100;
  border-radius: 50%;
  border: none;
  cursor: default;
  height: 30px;
  margin: 2px 0;
  outline: none;
  width: 30px;

  &--no-file {
    background-color: white;
  }

  &--pinned {
    background-color: $brand-color-primary;
  }
}
