.c-preview-modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  z-index: 5;
  width: 900px;
  max-width: 90%;
  height: fit-content;

  @include responsive(md) {
    padding: 2.5rem;
  }

  h4 {
    font-weight: 600;
  }

  &__container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 5rem 0;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
  }

  &__overlay {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  h2 {
    margin-top: 0;
  }

  &__buttons {
    display: flex;
    justify-content: end;
    margin-top: 2rem;
  
    button {
      margin-right: 1rem;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
}