.c-file-upload {
  background-color: $transparent;
  border-radius: 2rem;
  border: 1px solid $brand-color-secondary;
  cursor: pointer;
  height: 40px;
  text-align: center;
  transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;
  }

  &--small {
    height: 28px;
    padding: 0 12px;
  }

  &--medium,
  &--medium-big {
    height: 40px;
    padding: 0 15px;

    @include responsive(md) {
      padding: 0 20px;
    }
  }

  &--big {
    height: 50px;
    padding: 0 22px;
  }

  &__icon {
    transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  }

  &:hover &__icon {
    color: $white;
  }

  &--disabled,
  &--disabled:hover {
    background-color: $transparent;
    border: 1px solid $grey-200;
    color: $grey-200;
    cursor: default;
  }

  &--disabled:hover &__icon {
    color: $grey-200;
  }

  .rotate-270 {
    transform: rotate(270deg);
  }
}
