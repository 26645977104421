.c-process-step {
  // min-height: 75px;
  border-radius: 3px;
  border-top: 5px solid black;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  margin: 20px 0;

  &:hover {
    border-top: 5px solid $brand-color-secondary;
  }

  &:hover .c-process-step__step {
    border: 3px solid $brand-color-secondary;
  }

  &:hover .c-process-step__step-number {
    color: $brand-color-secondary;
  }

  &__step-number--selected {
    color: $brand-color-primary;
  }

  &--selected {
    border-top: 5px solid $brand-color-primary;
    border-right: 1px solid $brand-color-primary;
    border-left: 1px solid $brand-color-primary;
    border-bottom: 1px solid $brand-color-primary;
  }

  &__container {
    min-height: 75px;
    border-bottom: 1px solid $grey-300;
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    max-width: 520px;
    display: inline-block;
  }

  &__step {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 3px solid $grey-200;

    &--selected {
      border: 3px solid $brand-color-primary;
      color: $brand-color-secondary;
    }
  }

  &__details {
    padding: 20px 15px;

    @include responsive (md) {
      padding: 20px 30px;
    }
  }

  &__details-section {
    width: 100%;
    padding-right: 10px;

    @include responsive (md) {
      width: 65%;
    }
    img {
      max-width: 100%;
    }

  }

  &__details-content *a {
    color: $brand-color-secondary;
    text-decoration: none;
  }

  &__details-list {
    list-style: none;
  }

  &__details-list-item {
    &:before {
      content: "\2022";
      color: $brand-color-secondary;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  &__details-list-item-link {
    color: $brand-color-secondary;
  }

  &__download-button {
    background-color: $grey-300;
    padding: 4px 12px;
    // width: 160px;
    margin: 16px 0;
    border: none;
    border-radius: 3px;

    &-text {
      margin-right: 12px;
    }
  }
}

.react-select__control {
  border-radius: 0.25rem;
  border: 1px solid #9fa0a0;
  transition: 0.5s all;
}

.react-select__control--is-focused {
  border: 1px solid var(--brand-color-primary);
}

.react-select__multi-value {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.is-invalid .react-select__control {
  border-color: var(--red);
}

.add-tags {
  color: var(--brand-color-primary);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.no-results-tags {
  margin-top: 13px;
  margin-bottom: 13px;
  color: #9fa0a0;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.toggle-border-true {
  border-color: var(--brand-color-primary) !important;
}

.toggle-border-false {
  border-color: #565250 !important;
}

.error-message {
  color: var(--red);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 7px;
}

.list-item {
  padding: 10px;
  border: 1px solid #888;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #eee;
}

.process-btn-cancel {
  color: #565250 !important;
  border: none !important;
  padding: 10px 20px;
  margin-right: 11px;
  font-weight: 700;
  &:hover {
    background-color: #ced4da !important;
  }
}

.c-process-form {
  width: 70%;
  margin: 0 auto;
}	

.c-process-actions {
width: 85%;
padding-bottom: 16px;
}	

.react-select__menu {
 z-index: 3 !important;
}

.c-process-drag-icon {
  position: absolute;
  left: -12px;
  top: 48px;
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  border: 1px solid #9FA0A0;
  &:hover {
    background-color: #f4f5f5;
  }	
}	

.c-process-close-icon {
  position: absolute;
  left: -12px;
  top: 12px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #9FA0A0;
  &:hover {
    background-color: #f4f5f5;
  }	
  }	
  .react-select__menu-list	{
    max-height: 200px !important;
  }