.c-radio-button {
  display: block;
  margin-bottom: 24px;

  &__radio {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid $grey-100;
    position: relative;
    margin-right: 10px;

    &--active:before {
      content: '';
      position: absolute;
      background-color: $grey-100;
      height: 10px;
      width: 10px;
      top: 50%;
      left: 50%;
      display: inline-block;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &--mr-0 {
      margin-right: 0;
    }
  }

  &__value {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
    display: inline-block;
    overflow: hidden;

    &-container {
      display: inline-grid;
      min-width: 95%;
    }
  }
}
