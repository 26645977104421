.c-button {
  background-color: $transparent;
  border-radius: 2rem;
  border: 1px solid $brand-color-secondary;
  height: 40px;
  // min-width: 108px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  white-space: nowrap;

  &:focus-visible {
    outline: none;
  }



  &--small {
    height: 28px;
    padding: 0 12px;
  }

  &--medium,
  &--medium-big {
    height: 40px;
    padding: 0 15px;

    @include responsive(md) {
      padding: 0 20px;
    }
  }

  &--big {
    height: 50px;
    padding: 0 22px;
  }

  &--block {
    width: 100%;
    padding: 0 .6rem;

    @include responsive(lg) {
      width: 80%;
    }
  }

  &--link {
    border: 0;
    padding: 0;
    &:hover {
      background-color: $transparent;
      color: $brand-color-primary;
    }

    display: inline-block;
    position: relative;

    &:hover::after {
      transform: translateX(-50%) scaleX(1);
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 3px;
      width: 100%;
      height: 2px;
      background-color: $brand-color-primary;
      transform: translateX(-50%) scaleX(0);
      transition: transform 0.3s ease;
    }
  }

  &--dark{
    color: black !important;
    &:hover {
      background-color: $transparent;
      color: black !important;
    }
    &::after {
      background-color: black;
    }
  }

  &__icon {
    transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    &__icon {
      color: $white;
    }
    &__icon--link {
      color: $brand-color-primary;
    }
  }



  &:disabled,
  &:disabled:hover {
    background-color: $transparent;
    border: 1px solid $grey-200;
    color: $grey-200;
    cursor: default;
  }

  &:disabled:hover &__icon {
    color: $grey-200;
  }

  &--circle {
    border-radius: 100%;
    width: 33px;
    height: 33px;
    background-color: $transparent;
    border: 1px solid $brand-color-secondary;
  }

}

.bottom-button-container {
  margin: 36px 0 6px 0;

  @include responsive(md) {
    margin: 26px 0;
  }
}