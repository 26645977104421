:root {
  --white: #fff;
  --black: #000;
  --blue-100: #2A84E5; // Blue 1
  --grey-100: #565250; // Grey 1
  --grey-200: #9FA0A0; // Grey 2
  --grey-300: #F4F5F5; // Grey 3
  --dark-blue: #1B4061; // Dark Blue
  --green: #6CC56B; // Green
  --red: #E24325; // Red
  --blue: #2590E2; // Blue
  --yellow: #E6CB26; // Yellow
  --purple: #6E34FF; //Violet
}

// colors
$white: #fff;
$black: #000;
$blue-100: #2A84E5; // Blue 1
$grey-100: #565250; // Grey 1
$grey-200: #9FA0A0; // Grey 2
$grey-300: #F4F5F5; // Grey 3
$gray-400: #ced4da; //Grey 4
$grey-500: #E6E6E6; //Grey 5
$grey-600: #FBFBFB; //Grey 6
$grey-700: #9fa0a06d; //Grey 7
$dark-blue: #1B4061; // Dark Blue
$dark-gray: #212529;
$green: #6CC56B; // Green
$red: #E24325; // Red
$orange: #F07B0F; // Orange
$blue: #2590E2; // Blue
$yellow: #FFC107; // Yellow
$purple: #6E34FF; //Violet
$transparent: transparent;

$colors: (
  'brand-primary': $brand-color-primary,
  'brand-secondary': $brand-color-secondary,
  'brand-tertiary': $brand-color-tertiary,
  'brand-light': $brand-color-light,
  'white': #FFF,
  'black': #000,
  'light-blue': #85C0FF,
  'blue-100': #2A84E5,
  'grey-100': #565250,
  'grey-200': #9FA0A0,
  'grey-300': #F4F5F5,
  'gray-400': #CED4DA,
  'grey-500': #E6E6E6,
  'grey-600': #FBFBFB,
  'grey-700': #9fa0a06d,
  'dark-blue': #1B4061,
  'dark-gray': #212529,
  'green': #6CC56B,
  'red': #E24325,
  'orange': #F07B0F,
  'blue': #2590E2,
  'yellow': #E6CB26,
  'purple': #6E34FF,
  'light-purple': #BCAEDE,
  'transparent': transparent,
);

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$fWeights: 300, 400, 500, 600, 700, 800, 900;
$fSizes: (
  '2xs': 9px,
  'xs': 12px,
  'sm': 14px,
  'base': 16px,
  'lg': 18px,
  'xl': 20px,
  '2xl': 24px,
  '3xl': 30px,
  '4xl': 40px,
  '5xl': 50px,
  '6xl': 60px,
);

$portrait: 'portrait';
$landscape: 'landscape';