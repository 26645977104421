.c-profile-modal {

  &--blur {
    filter: brightness(50%);
  }

  &__photo-container {
    position: relative;
  }

  &__photo {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    max-width: 160px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__photo-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }

  &__photo-shadow:hover {
    background-color: black;
    opacity: .5;

    .c-profile-modal__photo-icon {
      color: $white;
      opacity: 1;
    }
  }

  &__photo-icon {
    opacity: 0;
  }

  &__section,
  .profile-section {
    display: flex;
    flex-direction: row;
    margin: 35px 0;

    @include responsive(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0;
    }
  }

  &__label,
  .modal-label {
    display: flex;
    align-items: center;
    min-width: 30%;
    margin-right: 15px;
    color: $grey-100;

    font: {
      size: 20px;
      weight: 300;
    }
  }

  &__title {
    color: $grey-100;
    letter-spacing: 0.5px;

    font: {
      size: 30px;
      weight: 500;
    }
  }

  &__input,
  .modal-input {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-self: center;
    border: 1px solid $grey-200;
    background-color: transparent;
    border-radius: 3px;
  }

  &__subtitle {
    color: $grey-200;
    letter-spacing: 0.5px;

    font: {
      size: 16px;
      weight: 300;
    }
  }

  &__separator {
    width: 100%;
    color: $grey-200;
    opacity: 1;
  }

  .modal-dialog {

    @include responsive(sm) {
      width: 70%;
      margin: 2rem auto 0;
    }

    @include responsive(md) {
      width: 60%;
      margin: 5rem auto 0;
      max-width: unset;
    }
  }

  .modal-content {
    padding: 28px;
  }

  .modal-header {
    align-items: flex-start;
    padding-bottom: 0;
  }

  .modal-footer,
  .modal-header {
    border: none;
  }

  &__user-image {
    cursor: pointer;
  }

  &__links {
    z-index: 2;
    top: 80px;
    box-shadow: 0 0 14.3605px rgba(0,0,0,.21);
    min-width: 180px;
  }
  &__links-section {
    @include responsive(md) {
    border-bottom: 1px solid $grey-200;
     }
  }
  &__link{
    font-weight: 400;
    font-size: 12px;

    @include responsive(md){
      font-weight: 700;
      font-size: 14px;
    }
  }
}

input[type="file"] {
  display: none;
}