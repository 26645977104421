.c-admin-search-bar {
  width: 100%;
  position: relative;

  &__search-bar-container {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    border: 1px solid $grey-200;

    @include responsive (md) {
      width: 300px;
      margin-top: unset;
    }

    @include responsive (lg) {
      width: 360px;
    }

    @include responsive (xl) {
      width: 450px;
    }

    &--active {
      border: 1px solid $brand-color-secondary;
    }

    &--error {
      border: 1px solid $red;
    }
  }

  &__text-input {
    border: none;
    width: 75%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  &__search-icon {
    margin: 0 15px;
  }

  &__delete-button {
    margin: 0 12px;
  }

  &__searched-result {
    position: absolute;
    top: 60px;
  }
}