.c-form-caption {
  margin: 26px 0 30px 0;

  @include responsive(md) {
    margin: 0 0 30px 0;
  }

  &__text {
    color: $grey-100;
    font-size: 14px;
  }

  &__link {
    color: $brand-color-primary;
    border-bottom: 1px solid;
    text-decoration: none;
    font-size: 14px;
  }
}
