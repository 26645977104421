.c-tab-button {

  &__title {
    padding: 5px 20px;
    border-bottom: 1px solid $grey-200;
    cursor: pointer;
    color: $grey-200;
    white-space: nowrap;

    &--active {
      border: 1px solid $grey-200;
      border-bottom: 1px solid transparent;
      color: $grey-100;
    }
  }
}