.o-app-container {
  padding-bottom: 111px;

  @include responsive (md) {
    padding-bottom: 0;
  }

  &__views {
    //Porque el sidebar tiene 27% sino romper el contenido de las views
    min-width: 73%;
  }
}