.c-text-link {
  color: $brand-color-light;
  padding-bottom: 7px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $brand-color-primary;
  }


  @include responsive(md) {
    font-size: 16px;
    color: $brand-color-secondary;
  }
}

.c-text-button {
  color: $brand-color-light;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0,;


  @include responsive(md) {
    color: $brand-color-secondary;
  }
}