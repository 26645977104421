.c-phone-input {
  position: relative;

  &__icon {
    user-select: none;
    transition: opacity .3s ease;

    &--left {
      position: absolute;
      top: 7px;
      left: 10px;
    }

    &--right {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

  &__field {
    padding-left: 52px !important;
  }
}