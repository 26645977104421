.c-clipboard-copy {
  padding: 7px 0 7px;
  height: 33px;
  z-index: 900;

  &__content {
    color: $grey-100;
    background-color: $white;
    white-space: nowrap;
    border-left: 3px solid $brand-color-primary;
    padding-left: 6px;
    height: 18px;
    line-height: 19px;
  }
}