.c-bottom-tab-navigator {
  bottom: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  position: fixed;
  padding-top: 10px;
  padding-bottom: 40px;
  justify-items: center;


  &__button {
    width: 20%;
    flex-grow: 0;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-items: center;
  }

  &__text {
    width: 100%;
    max-lines: 0;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-align: justify;
    display: inline-block;
    text-overflow: ellipsis;
  }
}
