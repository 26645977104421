.v-error-view {
  &__image {
    width: 80%;

    @include responsive (md) {
      width: 70%;
    }

    @include responsive (lg) {
      width: 50%;
    }
  }

  &__title {
    margin: 20px 0;

    @include responsive (md) {
      margin: 10px 0;
    }
  }

  &__subtitle {
    max-width: 496px;
    white-space: pre-line;
  }

  // @include responsive (md) {}
}
