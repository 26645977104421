.c-checkbox {
  $checkbox: &;
  display: flex;
  margin: 16px 0;

  &__check {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid $grey-200;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      border-radius: 2px;
    }

    &--small {
      width: 8px;
      height: 8px;
    }
  
    &--medium {
      width: 12px;
      height: 12px;
    }
  
    &--large {
      width: 18px;
      height: 18px;
    }

    &-container {
      position: relative;
      display: inline-block;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;

    &:checked+.c-checkbox__check {
      border-color: $brand-color-secondary;

      &::before {
        background-color: $brand-color-secondary;
      }
    }
  }

  &__name {
    margin-left: 10px;
    color: $grey-200;
    margin-top: -.25rem;
  }

  &--variant-icon {
    #{$checkbox} {
      &__check {
        border-width: 2px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: none;
        }

        // &--checked {
        //   border-color: $grey-100;
        // }
      }

      &__input:checked+.c-checkbox__check {
        border-color: $grey-100;
      }
    }
  }
}
