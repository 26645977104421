.o-image-background {
  background: url(../../brand/assets/background.jpg) no-repeat right center fixed;
  background-size: cover;
  min-height: 100vh;

  &-layer {
    background-color: rgba($black, .5);
    min-height: 100vh;
    width: 100%;
  }
}