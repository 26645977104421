.c-login-container {
  font-family: "Open Sans", sans-serif;
  background: url(../../brand/assets/background.jpg) no-repeat right center fixed;
  background-size: cover;
  min-height: 100vh;

  &__layer {
    background-color: rgba($black, .5);
    min-height: 100vh;
    width: 100%;
    padding: 0 1rem;
  }

  &__content {
    @include responsive(xs) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  &__form-container {
    background-color: $white;
    border-radius: 15px;
    padding: 20px 28px;
    box-shadow: 0 0 10px 4px rgb(0 0 0 / 28%);
    margin: 0 25px 1.3rem 25px;
    min-height: 400px;

    @include responsive(md) {
      margin: 0;
      min-height: 630px;
      padding: 20px 32px 30px;
      position: relative;
    }

    @include responsive(lg) {
      border-radius: 4px;
      max-width: 560px;
    }
  }
}