.c-error-screen {
  height: 100vh;
  background-color: $brand-color-light;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    color: $white;
    font-size: 2rem;
  }
}
