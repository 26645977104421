.c-form-links {
  letter-spacing: 1px;

  &__link,
  &__link:hover,
  &__separator {
    color: $white;

    @include responsive(md) {
      color: $grey-100;
    }
  }

  &__link {
    border-bottom: 1px solid $grey-100;
    text-decoration: none;
  }

  &__bottom-label {
    color: $grey-100;
    padding-right: 10px;
    display: block;
    cursor: pointer;

    &__2fa{
      color: $white;
      @include responsive(md) {
        color: $grey-100;
      }
    }
  }

  // &__forgot-password {
  //     color: $brand-color-primary;
  //     font-size: $text-100;
  //     font-weight: $link-w-400;
  //     padding-bottom: 5px;
  //     display: block;
  //     cursor: pointer;
  // }
}
