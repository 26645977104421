.c-tree-view {
	&__main {
		cursor: pointer;
		padding: 6px 10px;
		border-radius: 5px;
		background-color: #f1f4f8;
		display: inline-block;
	}

	&__icon-container {
		display: flex;
		align-items: center;
	}

	&__title {
		margin-left: 5px;
		font-weight: bold;
		font-size: 12px;
	}

	&__inside {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__container {
		margin-top: 10px;
		display: inline-block;
		background-color: #f1f4f8;
		padding: 16px 10px;
		border-radius: 5px;
		min-width: 210px;
		max-width: 100%;
		width: 100%;
		cursor: pointer;
		max-height: 100vh;
	}

	&--scrollable {
		max-height: 100vh;
		overflow-y: auto;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .1);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}
	}
}

.filled-icon {
	font-variation-settings: 'FILL' 1;
}