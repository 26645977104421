.c-file-name {
  // display: grid;
  // grid-template-columns: 2.375rem calc(100% - 2.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__text {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input {
    border: none;

    &:focus {
      outline: none;
    }
  }
}
