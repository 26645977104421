.c-chip {
  padding: 0.6rem;
  display: grid;
  grid-template-columns:  6fr 1fr;
  gap: 1rem;
  align-items: center;
  background-color: #f1f4f8;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;

  &.with-icon {
    grid-template-columns: 1fr 6fr 1fr;
  }

  &--small {
    grid-template-columns: 3fr 1fr;
    padding: 0 0.5rem;
    height: fit-content;
    border-radius: 3px;
    font-size: 14px;

    &.with-icon {
      grid-template-columns: 20px 3fr 1fr;
    }
  }

  &--main {
    background-color: $brand-color-secondary;
    color: white;
    height: 100%;

    .c-chip__close {
      color: white;
    }
  }

  &--error {
    background-color: rgba($red, 0.1);
  }

  &__icon {
    color: orange;

    &--error {
      color: $red;
    }
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__close {
    color: #ccc;
    text-align: end;
  }
}