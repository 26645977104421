@each $colorName,
$color in $colors {

  .o-bg-#{$colorName},
  .o-bg-#{$colorName}\:before:before,
  .o-bg-#{$colorName}\:after:after,
  .o-bg-#{$colorName}\:hover:hover {
    background-color: $color;
  }

  .o-cl-#{$colorName},
  .o-cl-#{$colorName}\:before:before,
  .o-cl-#{$colorName}\:after:after,
  .o-cl-#{$colorName}\:hover:hover,
  .o-cl-#{$colorName}\:placeholder::placeholder {
    color: $color;
  }
}
