.c-grid {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  border: 2px dashed transparent;

  @include responsive(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }

  @include responsive(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--no-gap {
    gap: 0;
  }

  &__mobile-actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    &--top {
      top: 14px;
    }
  }

  &__image {
    width: 85px;

    @include responsive(md) {
      width: 94px;
    }
  }

  &--sm-layout {
    @include responsive(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include responsive(xxl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--md-layout {
    @include responsive(md) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }

  &--xl-layout {
    @include responsive(xl) {
      grid-template-columns: repeat(6, minmax(100px, 1fr));
    }
  }

  .c-grid__actions {
    display: flex;

    @include responsive(md) {
      // display: none;

      &--selected {
        display: flex;
      }
    }
  }

  &__child {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 5px;

    &:hover {
      .c-grid__actions {
        display: flex;
      }
    }

    &--admin-bg {
      background-color: $grey-300;
    }

    &--admin:hover {
      background-color: $grey-300;
    }

    &--selected {
      .c-grid__actions {
        display: flex;
      }
    }

    &--border {
      border-color: $brand-color-primary;
      background-color: $grey-300;
    }

    &-section {
      width: 170px;
      max-height: 210px;

      &--bg {
        background-color: $grey-300;
      }

      // .c-grid__actions {
      //   display: none;

      //   @include responsive(md) {
      //     display: flex;
      //   }
      // }

      @include responsive(sm) {
        width: 200px;
        height: 200px;
      }

      @include responsive(md) {
        width: 205px;
        height: 205px;
      }

      @include responsive(lg) {
        width: 211px;
        height: 211px;
      }

      @include responsive(xl) {
        width: 218px;
        height: 218px;
      }

      @include responsive(xxl) {
        width: 220px;
        height: 220px;
      }

      @include responsive(xxxl) {
        width: 235px;
        height: 235px;
      }
    }
  }

  &__content {
    padding: 35px 0px 12px 0px;

    @include responsive(md) {
      padding: 35px 16px 12px 16px;
    }
  }

  &__type {
    bottom: 0;
    right: 2px;
  }

  &__file-name {
    word-break: break-all;
  }

  &__name {
    max-width: 100%;
    overflow: hidden;
    white-space: normal;

    &-text {
      width: 130px;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }

  &--documents {
    display: grid;
    @include responsive(md) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));grid-template-columns: repeat(3, 1fr);
      gap: 25px;
    }
  
    @include responsive(lg) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    
  }
}