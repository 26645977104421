.c-filter {
  padding: 10px 20px;
  min-width: 140px;
  justify-content: space-between;
  border-right: 1px solid $grey-300;

  @include responsive(md) {
    min-width: 200px;
  }

  &__text {
    color: $grey-100;
  }
}
