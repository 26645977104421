.v-dashboard {
  &__sections-container {
    gap: 10px;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;

    @include responsive(sm) {
      gap: 22px;
    }

    @include responsive(lg) {
      max-width: 750px;
      justify-content: flex-start;
    }
  }
}
