.c-button-goback {
  &__button {
    color: $brand-color-primary;
    text-decoration: none;
    position: relative;

    &:after {
      content: "";
      width: 80px;
      background-color: $brand-color-primary;
      height: 1px;
      position: absolute;
      top: 30px;
      right: 20%;
    }

    &:hover {
      color: $brand-color-primary;
    }
  }

}
