.c-tooltip {
  position: absolute;
  box-shadow: 6px 9px 5px -4px rgb(0 0 0 / 8%);
  background-color: $white;
  border-radius: 4px;
  z-index: 4;

  &--right,
  &--left {
    top: 50%;
    transform: translateY(-50%);
  }

  &--top,
  &--bottom {
    left: 50%;
    transform: translateX(-50%);
  }

  &--left {
    right: calc(100% + 5px);
  }

  &--right {
    left: calc(100% + 5px);
  }

  &--top {
    top: calc(-100% - 8px);
  }

  &--bottom {
    bottom: calc(-100% - 8px);
  }
}
