.c-context-menu {
  border-radius: 15px;
  z-index: 2;
  background-color: $white;
  width: 200px;
  padding: 11px 0;
  box-shadow: 0 -4px 20px -3px rgb(0 0 0 / 13%);

  &__item {
    display: flex;
    margin: 6px 0;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      font-weight: 600;
    }
  }
}
