.c-form-error {
  background: $white;
  box-shadow: 0 7px 15px rgb(0 0 0 / 10%);
  border-radius: 4px;
  bottom: 2.1rem;
  right: 0;
  position: absolute;
  padding: 10px 20px;
  white-space: nowrap;
  transform: translate(16%);

  &:after {
    content: '';
    position: absolute;
    left: 70%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 12px solid $transparent;
    border-right: 12px solid $transparent;
    border-top: 12px solid $white;
    clear: both;
  }

  &__error,
  &__message {
    display: block;
  }

  &__error {
    color: $red;
  }

  &__message {
    color: $grey-100;
  }
}
