.c-search-module-list {
  position: absolute;
  left: 22%;
  top: calc(100% + 8px);
  width: 78%;
  background-color: $white;
  box-shadow: 0 3px 15px 3px rgb(0 0 0 / 8%);
  z-index: 4;
  padding: 12px 0;
  border-radius: 3px;
  max-height: 395px;
  overflow-y: scroll;

  @include responsive(md) {
    left: 0;
    width: 100%;
  }

  &__spinner {
    font-size: 1rem;
  }

  &__empty-container {
    padding: 12px 21px;
  }

  &__empty-text {
    margin-left: 10px;
    font-size: 14px;
    color: $grey-100;

    &--bold {
      margin-left: 0;
      font-weight: 700;
    }
  }

  &__results {
    box-shadow: 0 -4px 20px -3px rgba(0, 0, 0, 0.13);
    background-color: #fff;
  }
}