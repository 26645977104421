.c-small-modal {
  &__title {
    max-width: 100px;
  }

  &__link {
    color: $grey-200;
    margin: 0 12px;
    cursor: pointer;
  }

  &__button-container {
    margin: 35px 0px 0px 0;
  }
}