@each $fsName,
$fs in $fSizes {

  .o-ft-#{$fsName} {
    font-size: $fs;
  }

  @each $brName1,
  $br1 in $breakpoints {
    .o-ft-#{$fsName}\@#{$brName1} {
      @include responsive($br1) {
        font-size: $fs  !important;
      }
    }
  }

  @each $fw in $fWeights {
    .o-ft-#{$fw} {
      font-weight: $fw;
    }

    @each $brName2,
    $br2 in $breakpoints {
      .o-ft-#{$fw}\@#{$brName2} {
        @include responsive($br2) {
          font-weight: $fw  !important;
        }
      }
    }

    .o-ft-#{$fsName}-#{$fw},
    .o-ft-#{$fsName}-#{$fw}\:placeholder::placeholder {
      font: {
        size: $fs;
        weight: $fw;
      }
    }

    @each $brName,
    $br in $breakpoints {
      .o-ft-#{$fsName}-#{$fw}\@#{$brName} {
        @include responsive($br) {
          font: {
            size: $fs  !important;
            weight: $fw  !important;
          }
        }
      }
    }
  }
}
