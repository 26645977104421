.o-admin-screen-container {
  padding: 2rem 2rem 10rem 2rem;

  @include responsive(md) {
    padding: 3% 3% 10rem 3%;
  }
}

.o-admin-scroll {
  overflow-y: scroll;
  height: 100dvh;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  @include responsive(md) {
    min-height: 92dvh;
  }
}
