.c-card {
  //min-width: 0;
  //aspect-ratio: 1;
  // min-width: 280px;
  // max-width: 280px;
  width: 80%;
  height: 260px;
  // flex-wrap: wrap;
  // min-height: 230px;
  // max-height: 250px;
  position: relative;
  box-shadow: 3px 8px 16px -8px rgba(0, 0, 0, 0.19);

  @include responsive (sm) {
    min-width: 230px;
    max-width: 240px;
    width: unset;
    height: unset;
    min-height: 230px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    // background-color: $brand-color-primary;
    padding: 60px 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__content {
    padding: 10px;
  }

  &__profile {
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  &__profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__name {
    margin: 0;
    margin-top: .5rem;
    margin-bottom: .4rem;
    color: $grey-100;
  }

  &__position {
    margin: 0;
    margin-bottom: .2rem;
    color: $grey-200;
  }

  &__company {
    margin: 0;
    margin-bottom: .8rem;
    color: $grey-200;
  }

  &__name,
  &__position,
  &__company {
    word-break: break-word;
  }

  &__link {
    background-color: $grey-100;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 2px 0;
    cursor: default;
  }
}