.v-contacts {

  &__spinner {
    font-size: 20px !important;

    @include responsive(md) {
      font-size: 30px !important;
    }
  }
}
