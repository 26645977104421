.c-order {

  &__button {
    background-color: $grey-300;
    border: none;
    border-radius: 3px;
    padding: 3px 6px;
    min-width: 85px;

    &--collapsible {
      padding: 0 0;
      min-width: auto;
    }
  }

  &__text {
    color: $grey-100;
  }
}
