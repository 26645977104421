.c-activity-section {
  padding: 50px 0;

  &__title {
    margin-bottom: 35px;
  }

  &__view-more-button {
    margin: 20px;
  }
}
