.o-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @include responsive(xl) {
    margin-bottom: 50px;
  }

  &-img {
    max-width: 60%;
    width: auto;

    @include responsive(md) {
      width: 200px;
    }

    @include responsive(lg) {
      width: 250px;
    }
  }
}