.c-tag {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: $grey-300;

  // &:hover {
  //   background-color: $brand-color-secondary;
  //   color: $white;
  // }

  // &:hover &__icon {
  //   color: $white;
  // }

  // &:hover &__text {
  //   color: $white;
  // }

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &__icon-left-container {
    margin-left: 15px;
  }

  &__icon-right-container {
    margin-right: 15px;
  }

  &__text {
    color: $grey-100;
  }

}
