.c-form-description {

  &--big {
    margin: 0 auto 50px auto;
    max-width: 235px;

    @include responsive(md) {
      margin: 0 auto 90px auto;
      max-width: 335px;

    }
  }

  &__text {
    color: $grey-100;
  }
}
