.c-action-modal {
  max-width: 346px;
  padding: 42px 32px;

  @include responsive(md) {
    max-width: 432px;
    padding: 42px;
  }

  &--md {
    max-width: 346px;
    padding: 42px 32px;

    @include responsive(md) {
      max-width: 485px;
      padding: 42px;
    }
  }

  &__content {
    box-shadow: 0 10px 20px rgba($black, 0.15);
  }

  &__header {
    padding: 30px 35px 0 35px;
  }

  &__title {
    display: block;
    width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $grey-100;

    @include responsive(md) {
      width: 350px;
    }

    &>span {
      color: $grey-100;
    }
  }

  &__file-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}