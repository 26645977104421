.c-sso-header {
  font-family: 'Open Sans', sans-serif;
  color: $grey-100;
  margin-bottom: 20px;

  @include responsive(md) {
    margin-bottom: 25px;
  }

  &__title {
    margin: 0;
    display: block;
  }

  &__subtitle {
    @include responsive(md) {
      margin-top: 5px;
    }
  }

  &__logo {
    margin-bottom: 20px;
    justify-content: center;

    @include responsive(md) {
      margin: 20px 0 15px;
    }
  }

  &__logo-img {
    max-height: 120px;
    max-width: 65%;
    aspect-ratio: 3/2;
    object-fit: contain;
    pointer-events: none;

    @include responsive(md) {
      width: unset;
      max-width: 62%;
      max-height: 130px;
    }
  }
}