.btn-franly {
  background-color: white;
  color: $brand-color-primary;
  border: 1px solid $brand-color-primary !important;
  border-radius: 2rem;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    background-color: $brand-color-primary;
    color: white;
  }
  &:active {
    box-shadow: none;
    background-color: $brand-color-primary;
    border-color: $brand-color-primary;
    color: white;
  }
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $brand-color-primary;
  border-color: $brand-color-primary;
}