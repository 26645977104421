.c-folder-filter {
  position: relative;

  &__main {
    display: flex;
    justify-content: space-between;
    padding: .5rem 1rem;
    border: 1px solid $grey-200;
    border-radius: 3px;
    width: 150px;
    cursor: pointer;
    user-select: none;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 1px 5px 12px #0003;
    border-radius: 3px;
    width: 250px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;
    background: white;
  }
}