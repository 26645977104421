.btn {
  display: none;
  border: none;

  @include responsive(md) {
    display: block;
  }

  &:active {
    border: none;
  }
}

.btn:hover {
  border-color: transparent;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
  border: none;
}

.c-header__search-container {
  // padding-left: 38px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.step-2__dropdown-list {
  list-style: none;
  overflow-y: scroll;
  max-height: 150px;
  position: absolute;
  top: 62px;
  width: 100%;
  background: $white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 3px 8px 16px -8px rgba(0, 0, 0, 0.19);
  margin: 0;
}

.step-2__add-button {
  color: #CA262F;
  top: 4px;
  right: 10px;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.c-button__spinner {
  margin-left: 10px;
  font-size: 12px !important;

  &--big {
    font-size: 1.25rem !important;
  }
}

.c-button {
  text-decoration: none !important;
}

.border-brand-secondary {
  border: 1px solid $brand-color-secondary
}

.c-toast--primary {
  background-color: $green !important;
}

.c-toast--danger {
  background-color: $red !important;
}

.c-toast--warning {
  border: 1px solid $brand-color-secondary !important;
  background-color: $brand-color-light !important;
}

.noty_type__warning {
  border: 1px solid #CC00FF !important;
  background-color: #F2BEFF !important;
  .noty_close_button{
    display: none !important;
  }
}

.c-toast--heading {
  color: white;
  font-size: 16px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word
}


.c-toast--body {
  color: white;
font-size: 16px;
font-family: Source Sans Pro;
font-weight: 400;
line-height: 24px;
word-wrap: break-word
}


#noty_layout__topRight {
  width: auto !important;
}

.c-files-modal {
  max-height: min(640px, calc(100vh - 3.5rem)) !important;
}

.c-table__row {
  height: 60px;

  &-data .c-checkbox {
    display: none !important;
  }

  .v-version-history__button {
    display: none !important;
  }

  &:hover &-data .c-checkbox {
    justify-content: center;
    display: flex !important;
  }

  &--selected &-data .c-checkbox {
    justify-content: center;
    display: flex !important;
  }

  &--selected .v-version-history__button,
  &:hover .v-version-history__button {
    justify-content: center;
    display: flex !important;
  }
}

.text-required {
  position: relative;
  display: inline-block;

  &::before {
    content: '*';
    color: red;
    position: absolute;
    right: -12px;
    top: -3px;
  }
}

.highlightTab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: fixed;
  pointer-events: none;
  padding-top: 10px;
  padding-bottom: 40px;
  z-index: 5 !important;
  background-color: white;
}

.overlayTab {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 6;
}

.highlightComponent {
  padding: 10px;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  border-radius: 100px;
  z-index: 4 !important;
  flex-direction: column;
  justify-content: center;
  background-color: white;

  @include responsive(md) {
    display: block;
    border-radius: 15px;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 3;
}

.full-height {
  height: 100%;
}

.c-search-bar-item__spinner {
  font-size: 12px !important;
}

// override notion renderer class
.notion-app {
  padding-bottom: 8em;

  @include responsive(xs) {
    padding-bottom: 16em;
  }
}

.notion-collection-card-cover img {
  object-position: top;
}

.notion-frame {
  padding: 0;
}

.notion-collection-view-type-title {
  font-family: var(--notion-font);
}

.notion-collection {
  align-self: flex-start;
  width: 100%;
}

.notion-collection-header {
  height: auto;
  padding: 10px 2px;
}

.select-form-container {
  border: 1px solid #9FA0A0;
  padding: 5px 10px;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  transition: 0.5s all;
}

.select-form {
  width: 100%;
  border: none;
  outline: none;
}