.c-toast {
  border-radius: 4px;
  box-shadow: 0 10px 17px rgba(85, 88, 85, 0.15);
  max-width: 700px;
  width: fit-content;

  &__button {
    background: unset;
  }
}
