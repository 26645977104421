.o-drag-border {
  border: 2px dashed transparent;

  &--color {
    border-color: $brand-color-primary;
  }
}

.o-min-height-dropzone {
  @include responsive(lg) {
    min-height: 550px;
  }
}