.c-dots-menu {
  &__menu {
    border-radius: 10px;
    box-shadow: 0 0 15px rgba($black, 0.1);
    list-style: none;
    top: 100%;
    min-width: 165px;
    max-width: 360px;
    width: max-content;
    z-index: 5;

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }
  }

  &__item {
    padding: 5px 0;
  }

  &__content {
    border-left: 4px solid transparent;
    font-weight: 400;
    padding: 1px 0;
    transition: border .3s ease;
  }

  &__item:hover & {
    &__content {
      border-left: 4px solid $brand_color-secondary;
      font-weight: 700;
      transition: border .3s ease;
    }
  }

  &__spinner {
    font-size: 1rem;
    color: $brand-color-secondary;
  }
}



.c-dots-menu__container {
  // border-top-right-radius: 25px;
  // border-top-left-radius: 25px;
}