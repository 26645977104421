.admin-form {

	input,
	textarea {
		padding: .5rem;
		resize: none;
		outline: none;

		&:focus {
			border: 2px solid $brand-color-secondary;
			border-radius: 3px;
		}
	}

  &__submit-button {
    @include responsive(md) {
      width: 200px;
    }
  }
}

.c-form-card {
  &--warn {
    border: 1.5px solid $brand-color-secondary;
  }

  &--required {
    position: relative;
  }

  &--required::after {
    content: '*';
    color: red;
  }

  &__drag-icon {
    visibility: hidden;
    cursor: pointer;
    user-select: none;
    position: absolute;
    left: -12px;
    top: 10px;

    @include responsive(md) {
      visibility: visible;
    }
  }

  &__corner-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: -12px;
    top: 16px;

    @include responsive(md) {
      visibility: hidden;
    }

    &__btn {
      cursor: pointer;
      user-select: none;
      display: flex;
      border-radius: 50%;
      background-color: $grey-300;
      border: 1px solid $grey-100;
    }
  }

  &__upload-button {
    width: 160px;
    font-size: 12px;

    @include responsive (md) {
      width: 245px;
      height: 50px;
      font-size: 20px;
    }
  }

  &__container {
    box-shadow: 1px 1px 8px #0002;
    border-radius: .3rem;

    @include responsive (md) {
      padding: 3rem 2rem 2rem 2rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  &__action {
    padding: .2rem;
    margin-right: 1rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--toggle {
      display: grid;
      grid-template-columns: 1fr 0fr;
      column-gap: .5rem;
      align-items: center;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 1;
    left: -160px;
    box-shadow: 1px 1px 8px #0002;
    border-radius: .5rem;
    width: 220px;
    background-color: #fff;
    padding: 0.8rem 0;

    li {
      list-style: none;
      padding: .4rem 1.2rem;
      cursor: pointer;
      background-color: #fff;

      &:hover {
        background-color: #f3f3f3;
        border-left: 5px solid $brand_color-secondary;
        font-weight: 700;
        transition: border .3s ease;
      }

      &.selected {
        font-weight: bold;
        border-left: 5px solid $brand_color-secondary;
      }

      &.selected::before,
      &:hover {
        background-color: #f3f3f3;
        border-left: 5px solid $brand_color-secondary;
        font-weight: 700;
        transition: border .3s ease;
      }
    }
  }
}