.v-privacy-terms {
  padding: 40px;

  @include responsive(md) {
    padding: 80px
  }

  &__content {
    background-color: $white;
    border-radius: 15px;
    padding: 20px 15px;
    box-shadow: 0 0 10px 4px rgb(0 0 0 / 28%);
    margin-bottom: 1.5rem;

    @include responsive(md) {
      padding: 70px 60px;
      border-radius: 4px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;

    @include responsive(md) {
      font-weight: 700;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;

    @include responsive(md) {
      font-weight: 600;
    }
  }

  &__section {
    margin-bottom: 25px;
  }

  &__text {
    font-size: 14px;

    @include responsive(md) {
      font-size: 18px
    }
  }
}
