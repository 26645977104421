.c-folder {
	&__father {
		display: flex;
		align-items: center;

		&:not(:first-child) {
			margin-top: 16px;
		}
	}
	
	&__name {
		margin-left: 5px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-size: 12px;
	}
	
	&__sub-folder-container {
		margin-left: 16px;
		margin-top: 16px;
	}
}

