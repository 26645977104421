.c-form-header {
  font-family: 'Open Sans', sans-serif;
  color: $grey-100;
  margin-bottom: 18px;

  @include responsive(md) {
    margin-bottom: 20px;
  }

  &__title {
    margin: 0;
    display: block;
  }

  &__subtitle {
    margin-top: 20px;

    @include responsive(md) {
      margin-top: 0px;
    }
  }

  &__logo {
    margin-bottom: 20px 0 16px 0;
    justify-content: center;

    @include responsive(md) {
      margin: 30px 0 20px;
    }

    @include responsive(xxl) {
      margin: 20px 0 10px;
    }
  }

  &__logo-img {
    max-height: 130px;
    // margin-top: 20px;
    width: 65%;
    max-width: 280px;
    aspect-ratio: 3/2;
    object-fit: contain;
    pointer-events: none;

    @include responsive(md) {
      width: unset;
      max-width: 60%;
    }
  }
  &__portal {
    justify-content: center;
  }
  &__portal-logo-img {
    max-width: 118px;
  }
  &__onboarding-logo-img{
    max-width: 160px;
    margin: 35px 0;
  }
}