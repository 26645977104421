.c-comments {
  &__container {
    border: 1px solid #0005;
    border-radius: 4px;
  }

  &__profile {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 3px;

    @include responsive(md) {
      width: 25px;
      height: 25px;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f4f5f5;
    transition: background .3s ease;
    border-radius: 3px;

    &--error {
      color: $red;
      border: 1px solid $red
    }

    &:hover {
      background-color: #e7e7e7;
    }
  }
}