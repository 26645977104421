.c-search-module-item {
  padding: 12px 21px;
  border-bottom: 0.5px solid $grey-200;

  &:nth-last-child(1) {
    border-bottom: none;
  }

  &__img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__text {
    color: $grey-100;
    font-size: 14px;
    margin-left: 10px;
  }
}
