.c-featured-post-widget {
  padding: 20px;
  background-color: $white;
  box-shadow: 0 3px 15px 3px rgb(0 0 0 / 8%);
  margin: 0 0 22px 0;
  min-height: 350px;
  @include responsive(lg) {
    width: 260px;
    margin: 0 0 0 22px
  }

  @include responsive(xl) {
    width: 230px;
  }

  &-month {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}