:root {
  --brand-color-primary: #E4002B;
  --brand-color-secondary: #E4002B;
  --brand-color-tertiary: #E4002B;
  --brand-color-light: #E4002B;
  --gradient-color: linear-gradient(180deg, rgba(228, 0, 43, 0.30) 0%, #E4002B 100%);
}

$brand-color-primary: #E4002B;
$brand-color-secondary: #E4002B;
$brand-color-tertiary: #E4002B;
$brand-color-light: #E4002B;
$gradient-color: linear-gradient(180deg, rgba(228, 0, 43, 0.30) 0%, #E4002B 100%);


$brand-color: #E4002B;
$brand-light: #E4002B;