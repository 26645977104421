.c-nda-modal {
  height: 100%;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  z-index: 1060 !important;
  background-color: $white;

  &__header {
    padding: 20px 25px 20px;
  }

  &__title {
    color: $grey-100;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
  }

  &__content {
    padding: 20px;
    border-radius: 5px;
    border-bottom: 1px solid $grey-200;
    border-top: 1px solid $grey-200;
    overflow-y: scroll;
    height: 64vh;
  }

  &__table,
  &__table td {
    border: 1px solid $grey-200;
    padding: 10px;
  }

  &__table td,
  &__table th {
    width: 50%;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  &__footer {
    padding: 20px;
    position: fixed;
    bottom: 0;
    height: 150px;
    flex-direction: column;
    background-color: $white;
    width: 100%;
  }

  &__footer-submit-btn {
    width: 200px;
  }

  &__disclosure {
    max-width: 300px;
  }

  @include responsive(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__header {
      padding: 50px 55px 30px;
    }

    &__title {
      font-size: 25px;
      font-weight: 600;
      margin: 30px 0;
    }

    &__content {
      height: 67vh;
    }

    &__disclosure {
      max-width: unset;
    }

    &__footer {
      position: inherit;
      bottom: 0;
      height: 90px;
      flex-direction: row;
      background-color: $white;
    }

    &__footer-submit-btn {
      width: 230px;
    }
  }
}