.c-folder-item {

  &__container {
    width: 165px;
    height: 165px;
    border-radius: 3px;
    background-size: cover;
    background-repeat: no-repeat;
    text-decoration: none;

    @include responsive(sm) {
      width: 190px;
      height: 190px;
    }

    @include responsive(md) {
      width: 200px;
      height: 200px;
    }

    @include responsive(xl) {
      width: 220px;
      height: 220px;
    }

    @include responsive(xxl) {
      width: 215px;
      height: 215px;
    }

    @include responsive(xxxl) {
      width: 235px;
      height: 235px;
    }
  }

  &__card {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-size: contain;
  }

  &__card-content {
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;

    &--new {
      background: $brand-color-primary;
    }

    &:hover {
      background: $gradient-color;
      transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }

    &:hover .c-folder-item__card-icon {
      background-color: $white;
    }

    &:hover .c-folder-item__hovered-icon {
      color: $brand-color-primary;
    }

    &:hover .c-folder-item__card-description {
      margin-bottom: 20px;
      transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }
  }

  &__card-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  &__card-icon {
    width: 45px;
    height: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: 2px solid white;
    margin-bottom: 8px;

  }

  &__title {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto 12px;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    word-wrap: break-word;
    max-width: 220px;

    &-section {
      max-width: 100%;
    }
  }

  &__tab-section {
    transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  }

  &__button-tab {
    border: 1px solid $white;
    padding: 0 10px;
    width: 68px;

    &:hover {
      border: 1px solid $white;
      background-color: $white;
      color: $brand-color-primary;
    }

    @include responsive(xl) {
      width: 58px;
    }

    @include responsive(xxxl) {
      width: 64px;
    }
  }
}