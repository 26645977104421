.c-header {
  // padding: 20px 0 0 0;
  background: #fff;

  @include responsive (md) {
    border-bottom: 1px solid #9FA0A0;
    padding: 11px 0;
  }

  &__container {
    background: #fff;
  }

  &__mobile-logo {
    width: 190px;
    padding: 10px;
    border-radius: 15px;
    min-height: 80px;
    justify-content: center;

    &__img {
      max-width: 140px;
      max-height: 50px;
      vertical-align: baseline;
    }
  }

  &__logo-container {
    margin-right: 26px;
  }

  &__desktop-logo-container {
    border-radius: 15px;
    padding: 10px;
    &:hover{
      background-color: $grey-300;
    }
  }

  &__desktop-logo {
    max-width: 170px;
    height: 50px;
    object-fit: contain;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  &__main-section {
    padding: 20px 0;
    border-bottom: 1px solid $grey-200;
  }

  &__search-results-container {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;

    @include responsive (md) {
      top: 60px;
    }
  }

  &__brand-switcher {
    width: 220px;
    z-index: 20;
    text-align: left;
    padding: 5px 0;
    right: 0;
    box-shadow: 0 0 14.3605px rgba(0, 0, 0, .21);
    border-radius: 10px;
    right: -43px;
    top: 60px;

    @include responsive (md) {
      right: -36px;
      top: 55px;
    }
  }

  &__brand-title {
    transition: border .3s ease;

    &:hover {
      border-left: 4px solid $brand_color-secondary;
      transition: border .3s ease;
    }
  }
  &__spinner-container{
    min-height: 150px;
  }
}