.c-store-information-data {
  width: 100%;
  margin: 20px 0 25px 0;

  &__data {
    border: 1px solid $grey-300;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    min-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    line-height: 36px;
  }

  &__tags {
    padding: 8px 0;
    border-radius: 3px;
    flex-wrap: wrap;
  }

  &__tag {
    margin: 6px;
    padding: 8px 12px;
    background-color: $grey-300;
  }
}