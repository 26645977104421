.c-backpack-header {
  display: grid;
  align-items: baseline;
  grid-template-columns: 1fr;

  @include responsive(md) {
    grid-template-columns: 2fr 1fr;
  }

  &__column {
    display: grid;
    align-items: baseline;
    grid-template-columns: 4fr 1fr;

    @include responsive(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__links {
    display: grid;
    align-items: baseline;
    grid-template-columns: 1fr 1fr;
    margin-top: 1.5rem;

    @include responsive(md) {
      margin-top: 0;
    }
  }

  &__link {
    overflow: hidden;
    padding-left: 0.5rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-bottom: 0;
  }
}