.c-message {
  position: absolute;
  background-color: $grey-100;
  color: $white;
  padding: 4px 11px;
  border-radius: 2px;
  box-shadow: 0 -4px 20px -3px rgb(0 0 0 / 13%);
  transition: 0.4s cubic-bezier(0.55, 0, 0.1, 1);

  &--top {
    top: -100%;
  }

  &--bottom {
    bottom: -100%;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}
