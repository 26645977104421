.c-step-attachments {
  &__section {
    width: 100%;
    height: 200px;
    overflow: auto;
    visibility: hidden;
    background-color: $grey-300;
    transition: visibility 0.1s;
    overflow-y: scroll;

    &:hover {
      transition: visibility 0s 0.1s;
      background-color: $grey-300;
    }

    &:hover,
    &:focus {
      visibility: visible;
    }

    @include responsive (md) {
      width: 240px;
      height: 200px;
    }
  }

  &__content {
    visibility: visible;
  }
}
