.c-file-action-button {

  &__action {
    padding: 15px 0;
    border-radius: 10px;
    position: absolute;
    right: 5px;
    top: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  }

  &__section {
    padding: 0 20px;

    &:hover {
      border-left: 3px solid #6E34FF;
      transition: all 0.2s ease;
    }
  }

  &__text {
    margin-left: 5px;

    &:hover {
      font-weight: 700;
      transition: all 0.2s ease;
    }
  }
}
