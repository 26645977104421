.c-store-card {
  margin-bottom: 10px;

  &__container {
    border: 1px solid rgba(128, 128, 128, 0.384);
    width: 165px;
    height: 180px;
    border-radius: 3px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 14.3605px rgba(0, 0, 0, 0.21);

    @include responsive(sm) {
      width: 190px;
      height: 190px;
    }

    @include responsive(md) {
      width: 213px;
      height: 215px;
    }

    @include responsive(xl) {
      width: 220px;
      height: 220px;
    }

    @include responsive(xxl) {
      width: 225px;
      height: 230px;
    }
  }

  &__info {
    padding: 10px 15px;
    height: calc(100% - 95px);
  }

  &__bg {
    width: 100%;
    height: 42%;
    background-size: contain;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  &__name {
    color: $grey-100;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    @include responsive(md) {
      -webkit-line-clamp: 2;
    }
  }

  &__number {
    color: $brand-color-primary;
  }

  &__city-info {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__address {
    color: $grey-100;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__address-container {
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
}