.c-card-list {
  &__row {
    display: grid;
    grid-gap: 15px;
    justify-items: center;
    grid-template-columns: 1fr;

    @include responsive(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include responsive(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include responsive(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
