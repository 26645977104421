.c-filter-tag {
  background-color: $grey-300;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 3px;


  &:nth-last-child(1) {
    background-color: $brand-color-secondary;
    padding-left: 12px;
    padding-right: 12px;
  }

  &:nth-last-child(1) &__text,
  &:nth-last-child(1) &__icon {
    color: $white;
  }

  &:nth-last-child(1) &__text {
    font-weight: 600;
  }

  &__text {
    color: $grey-100;
    padding-right: 6px;
  }
}
