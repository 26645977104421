.c-floating-filter-view {
  width: 100%;
  position: absolute;
  left: 1px;
  margin-top: 18px;
  background-color: $white;
  box-shadow: -4px 10px 27px 2px rgb(0 0 0 / 13%);
  padding-top: 20px;
  z-index: 3;

  &__title {
    color: $black;
    padding-left: 25px;
  }
}
