.c-breadcrumb {
  &__text {
    color: $grey-200;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      color: $grey-100;
      text-decoration: none;
    }

    &--active {
      min-width: 100px;
      color: $grey-100;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    &--with-separator {
      &:after {
        content: '/';
        margin: 0 5px;
      }
  
      &:hover:after {
        color: $grey-200;
      }
  
      &:last-child:after {
        display: none;
      }
    }
  }

  &__separator {
    color: $grey-200;
    margin: 0 5px 0 5px;

    &:last-child {
      display: none;
    }
  }

  &__pop-up {

    &-list {
      left: 0;
      top: 20px;
      z-index: 5;
      display: none;
      overflow: hidden;
      max-width: 200px;
      max-height: 176px;
      overflow-y: scroll;
      border-radius: 10px;
      background-color: $white;
      padding: 10px 15px 0 15px;
      box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
    }

    &:hover &-list {
      display: block;
    }

    &-item {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      padding-bottom: 10px;
      text-decoration: none;
      text-overflow: ellipsis;

      &:hover {
        color: $grey-100;
        font-weight: 600;
      }
    }
  }
}
