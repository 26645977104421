.c-tags-pop-up {
  &__tags {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 80%;
  }

  &__list {
    display: none;
    top: 0;
    left: 0;
    width: auto;
    z-index: 5;
    background-color: $white;
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 10px;
  }

  &__item {
    display: block;
    padding: 10px 16px;
  }

  &:hover &__list {
    display: block;
  }
}
