.c-current-version-button {
  height: 40px;
  color: $white;
  // padding: 0 20px;
  border-radius: 2rem;
  background-color: $grey-200;
  border: 1px solid $grey-200;
  width: 132px;
  cursor: default !important;
}
