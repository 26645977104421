.c-search-bar-result {
  min-height: 50px;
  width: 100%;
  background-color: $white;
  z-index: 4;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  flex-wrap: wrap;

  &--admin {
    min-height: 50px;
    max-height: 330px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
    flex-wrap: wrap;
    overflow-y: scroll;
    top: 170px;
  }

  &__content {
    min-height: 50px;
    max-height: 180px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    flex-wrap: wrap;
    overflow-y: auto;
  }

  &__title-section {
    padding: 15px 20px;
  }

  &__list {
    min-height: 0px;
    max-height: 335px;
    overflow-y: auto;
  }

  &__file-result {
    width: 100%;
    border-top: 1px solid $grey-700;
    // border-bottom: 1px solid $grey-700;
    padding: 15px 20px 15px 20px;
  }

  &__icon-container {
    width: 44px;
    aspect-ratio: 1;
  }

  &__img {
    // width: 100%;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;

    @include responsive(lg) {
      width: 35px;
      height: 35px;
    }
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include responsive(sm) {
      font-size: 14px;
    }

    @include responsive(md) {
      font-size: 15px;
    }

    &:hover {
      overflow: hidden;
      white-space: normal;
      text-overflow: unset;
      overflow-wrap: break-word;
    }
  }
}