.c-order-list {
  border-radius: 15px;
  box-shadow: 0 -4px 20px -3px rgb(0 0 0 / 13%);
  z-index: 2;
  background-color: $white;
  width: 105px;
  top: 35px;
  padding: 10px 0;
  right: 0;

  &__item {
    color: $grey-100;
    display: block;
    padding-left: 10px;
    margin: 6px 0;
    list-style: none;

    &--active {
      border-left: 3px solid $brand-color-secondary;
      font-weight: 700;
    }
  }
}
