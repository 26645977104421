.c-contact-table-row {
  &__col {
    padding: .9rem .5rem;
    border-bottom: .5px solid $grey-200;
    min-width: 150px;

    @include responsive(lg) {
      min-width: 160px;
    }
  }

  &__img-container {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__text {
    color: $grey-200;
  }

  // &__name {
  //   color: $grey-100;
  //   word-break: break-all;
  //   width: 150px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;

  //   @include responsive(sm) {
  //     width: 230px;
  //   }

  //   @include responsive(md) {
  //     width: 250px;
  //   }

  //   @include responsive(lg) {
  //     width: 280px;
  //   }
  // }

  &__name {
    color: $grey-100;
    word-break: break-all;
  }

  // &__short-name {
  //   width: 75px;
  //   word-break: break-all;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;

  //   @include responsive(xs) {
  //     width: 150px;
  //   }

  //   @include responsive(sm) {
  //     width: 230px;
  //   }

  //   @include responsive(md) {
  //     width: 250px;
  //   }

  //   @include responsive(lg) {
  //     width: 300px;
  //   }
  // }


  &--collapsable {
    display: none;

    @include responsive(md) {
      display: table-cell;
    }
  }
}
