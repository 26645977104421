.c-filter-module {
  &__filter {
    position: relative;
  }

  &__container {
    // margin-bottom: 32px;
  }

  &__filter-section-shadow {
    display: flex;
    box-shadow: 6px 10px 15px -4px rgb(0 0 0 / 8%);
    height: 58px;
    width: 100%;
    z-index: 2;

    @include responsive (md) {
      width: 60%;
    }
  }

  &__filter-border {
    border: 1px solid $grey-200;
    height: 58px;
  }

  &__options-section {
    position: relative;
    padding: 0;
    // margin-bottom: 10px;

    @include responsive(md) {
      margin: unset;
    }
  }
}