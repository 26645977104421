.c-text-field .form-select:focus {
  outline: 0;
  box-shadow: none;
  border-color: $brand-color-primary;

}

.c-text-field .form-select::placeholder {
  color: $grey-200;
}
